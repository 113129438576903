import { createBrowserRouter } from "react-router-dom";
// components
import App from "./components/App";
import AppLoader from "./components/AppLoader";
import Login, { loginAction } from "./components/Login";
import QrScan, { qrLoader, qrAction } from "./components/QrScan";
import Instances from "./components/Instance/Instances";
import InstanceTable from "./components/Instance/InstanceTable";
import InstanceActionsModal, {
  modalActionsLoader,
  modalAction,
} from "./components/InstanceActionsModal";
import InstanceInformationModal, {
  modalInformationLoader,
  //modalInformationAction,
} from "./components/InstanceInformationModal";
import InstanceUploadModal, {
  modalUploadLoader,
} from "./components/InstanceUploadModal";
import { createPortal } from "react-dom";
import Delete, { deleteLoader } from "./components/Delete";
import AddInstance, {
  addInstanceLoader,
  addInstanceAction,
} from "./components/AddInstance";
import DBInstanceTable from "./components/DBInstance/DBInstanceTable";
import DBInstanceActionsModal from "./components/DBInstance/DBInstanceActionsModal";
import DBInstanceInformationModal from "./components/DBInstance/DBInstanceInformationModal";
import DBInstanceSnapshotsModal from "./components/DBInstance/DBInstanceSnapshotsModal";
import Backup, { backupLoader } from "./components/Backup";
import Report from "./components/Report/Report";
import InstanceCommandsModal, { 
  modalCommandsLoader 
} from "./components/InstanceCommandsModal";
import InstancesDynamoTable from "./components/DynamoReport/InstancesDynamoTable";
import DynamoReport from "./components/DynamoReport/DynamoReport";
import LayoutReport from "./components/DynamoReport/LayoutReport";
import InstanceDynamoInformationModal, { 
  modalDynamoInformationLoader 
} from "./components/DynamoReport/InstanceDynamoInformationModal";
import AddSharedServer, { addSharedServerAction } from "./components/AddSharedServer";
import LmsActionsModal, { lmsActionsLoader } from "./components/LMSActionsModal";
import AddStandaloneInstance, {
  addInstanceStandaloneLoader
} from "./components/AddStandaloneInstance";
import LMSDeleteModal, { lmsDeleteLoader } from "./components/LMSDeleteModal";
import LMSUpgradeModal, {lmsUpgradeLoader} from "./components/Instance/LMSUpgradeModal";
import Upgrade, { upgradeLoader } from "./components/Upgrade";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    action: loginAction,
    errorElement: <div>Oops! There was an error.</div>,
  },
  {
    path: "qrcode",
    element: <QrScan />,
    loader: qrLoader,
    action: qrAction,
    errorElement: <div>Oops! Unable to get qr code.</div>,
  },
  {
    path: "backups/:uniqueId",
    element: <Backup />,
    errorElement: <div>Oops! Unable to download backup file.</div>,
    loader: backupLoader,
  },
  {
    path: "home",
    element: <App />,
    errorElement: <div>Oops! Unable to get instances.</div>,
    loader: AppLoader,
    children: [
      {
        index: true,
        element: <Instances />,
        errorElement: <div>Oops! Unable to get the instances.</div>,
      },
      {
        path: "instances",
        element: <InstanceTable />,
        errorElement: <div>Oops! Unable to get the instances.</div>,
        children: [
          {
            path: "actions/:instanceId/:instanceName/:instanceDeployment",
            element: createPortal(<InstanceActionsModal />, document.body),
            loader: modalActionsLoader,
            action: modalAction,
          },
          {
            path: "commands/:instanceId/:instanceIp/:instanceName",
            element: createPortal(<InstanceCommandsModal />, document.body),
            loader: modalCommandsLoader,
          },
          {
            path: "updloads/:instanceId/:instanceIp/:instanceLMS",
            element: createPortal(<InstanceUploadModal />, document.body),
            loader: modalUploadLoader,
          },
          {
            path: "information/:instanceId/:instanceIp/:instanceName/:instanceDeployment/:instanceVersion?",
            element: createPortal(<InstanceInformationModal />, document.body),
            loader: modalInformationLoader,
            //action: modalInformationAction,
            children: [
              {
                path: "operations/:volumeId/:volumeName?/:volumeDomain?",
                element: createPortal(<LmsActionsModal />, document.body),
                loader: lmsActionsLoader
              },
              {
                path: "delete/:volumeId/:volumeName?",
                element: createPortal(<LMSDeleteModal />, document.body),
                loader: lmsDeleteLoader
              },
              {
                path: "upgrade/:volumeId?/:name?",
                element: createPortal(<LMSUpgradeModal />, document.body),
                loader: lmsUpgradeLoader
              }
            ],
          },
        ],
      },
      {
        path: "delete/:token/:action",
        element: <Delete />,
        errorElement: <div>Oops! Unable to get the resources.</div>,
        loader: deleteLoader,
      },
      {
        path: "upgrade/:token/:action",
        element: <Upgrade />,
        errorElement: <div>Oops! Unable to get the resources.</div>,
        loader: upgradeLoader,
      },
      {
        path: "instances/add",
        element: <AddInstance />,
        errorElement: <div>Oops! Unable to load the view.</div>,
        loader: addInstanceLoader,
        action: addInstanceAction,
      },
      {
        path: "instances/confirm-add/:token",
        element: <AddStandaloneInstance />,
        errorElement: <div>Oops! Unable to load the view.</div>,
        loader: addInstanceStandaloneLoader
      },
      {
        path: "instances/add-shared-server",
        element: <AddSharedServer />,
        errorElement: <div>Oops! Unable to load the view.</div>,
        action: addSharedServerAction,
      },
      {
        path: "dbinstances",
        element: <DBInstanceTable />,
        errorElement: <div>Oops! Unable to get the dbinstances.</div>,
        children: [
          {
            path: "actions/:dbInstanceId",
            element: createPortal(<DBInstanceActionsModal />, document.body),
          },
          {
            path: "dbsnapshots/:dbInstanceId",
            element: createPortal(<DBInstanceSnapshotsModal />, document.body),
          },
          {
            path: "information/:dbInstanceId",
            element: createPortal(
              <DBInstanceInformationModal />,
              document.body
            ),
          },
        ],
      },
    ],
  },
  {
    path: "usability-report",
    element: <Report />,
    errorElement: <div>Oops! There was an error.</div>,
  },
  {
    path: "reports",
    element: <LayoutReport />,
    errorElement: <div>Oops! Unable to get instances.</div>,
    children: [
      {
        index: true,
        element: <DynamoReport />,
        errorElement: <div>Oops! Unable to get the instances.</div>,
      },
      {
        path: "dymano-instances",
        element: <InstancesDynamoTable />,
        errorElement: <div>Oops! There was an error.</div>,
        children: [
          {
            path: "information/:id/:name",
            element: createPortal(<InstanceDynamoInformationModal />, document.body),
            loader: modalDynamoInformationLoader,
          }
        ]
      }
    ]
  }
]);

export default router;
