import { useRef, useState } from "react";
import {
  useLoaderData,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";
import { useAuth, ROLE } from "../../auth/AuthContext";

interface ILoaderModal {
  instanceId: string;
  volumeId: string;
  volumeName?: string;
}

const LMSDeleteModal = () => {
  const loader = useLoaderData() as ILoaderModal;
  const region = useOutletContext();
  const { canUser } = useAuth();

  const instanceId = loader.instanceId;
  const volumeId = loader.volumeId;
  const volumeName = loader.volumeName;
  //console.log('lms delete modal: ', loader)

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [lmsName, setLmsName] = useState<string>("");
  const inputDeleteDomainRef = useRef<HTMLInputElement>(null);

  const deleteVolume = async (): Promise<void> => {
    if (!instanceId || !volumeId || !volumeName) {
      alert("Invalid parameters.");
      return;
    }

    if (lmsName !== volumeName) {
      alert(`Please enter the correct LMS name (${volumeName}) to confirm deletion.`);
      return;
    }

    // Get the checkbox value, if checked, set deleteDomain to false and vice versa 
    const deleteDomain = inputDeleteDomainRef.current ? !inputDeleteDomainRef.current.checked : true;
    console.log("deleteDomain: ", deleteDomain);

    // Begin operation
    console.log("Begin the delete-request volume operation");
    setIsLoading(true); //show loading element

    //otherwise
    try {
      const response = await api.post(`/instances/delete-request`, {
        resources: {
          instanceId: instanceId,
          volumeId: volumeId,
        },
        deleteLMS: true,
        region: region,
        deleteDomain: deleteDomain,
      });

      const data = response.data;
      console.log("response: ", data);

      if (response.status === 200 && data.success) {
        alert("The deletion request has been completed successfully.");
      }
    } catch (error: any) {
      if (error instanceof AxiosError && error.response) {
        const { data } = error.response;
        if (data.error && data.message) {
          alert(data.message);
        } else {
          alert("The deletion request could not be completed.");
        }
      } else alert(error.message);
    }

    setIsLoading(false); //hide loading element
  };

  return (
    <>
      <div className="modal-overlay-second">
        <div className={isLoading  ? "loading" : "" }></div>
        <div className="modal-actions">
          <h4 className="title-modal-lms-actions">{volumeName}</h4>
          <button
            className="close-modal-button"
            onClick={() => navigate(-1)}
          >
            x{" "}
          </button>
          <div className="modal-actions-body">
            <div className="section-modal-lms-action">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>Delete</h5>
                </div>
              </div>
              {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
              <div className="section-content-lms-actions">
                  <div className="row mb-1">
                    <label
                      className="col-sm-4 col-form-label font-weight-500 text-end"
                      htmlFor="lmsName"
                    >
                      LMS Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        name="lmsName"
                        className="form-control"
                        value={lmsName}
                        onChange={(e) => setLmsName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                      <div className="form-check">
                        <input
                          ref={inputDeleteDomainRef}
                          className="form-check-input"
                          type="checkbox"
                          name="noDeleteDomain"
                          id="noDeleteDomain"
                        />
                        <label className="form-check-label font-weight-500" htmlFor="noDeleteDomain">
                          Do not delete domain
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 text-end mr div-delete-instance">
                    <div className="col-sm-12">
                      <button
                        className="btn btn-danger btn-main"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to delete the LMS ${volumeName}?`))
                            deleteVolume();
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
              </div>
              ) : 'You do not have permission to perform this action.' }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LMSDeleteModal;
