import { ActionFunctionArgs } from "react-router-dom";

const loader = ({ params }: ActionFunctionArgs) => {
  return {
    instanceId: params.instanceId,
    instanceIp: params.instanceIp,
    volumeId: params.volumeId,
    name: params.name,
    deployment: params.instanceDeployment
  };
};

export default loader;
