import { useEffect, useState } from "react";
import { useAuth, ROLE } from "../../auth/AuthContext";
import { redirect, useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";

interface ILoaderUpgrade {
    token: string;
    action: string;
}

type DataType = {
    instanceName: string;
    domain: string;
    instanceIp: string;
    instanceId: string;
    dbInstanceId: string;
    appDirectory: string;
    volumeId?: string;
    volumeName?: string;
    lms: string;
    dbName: string;
};

const Upgrade = () => {
    const { canUser } = useAuth();
    const loaderData = useLoaderData() as ILoaderUpgrade;
    const navigation = useNavigation();
    const navigate = useNavigate();

    const region =
      window.localStorage.getItem("moodle-console-region") ?? "us-west-2";

    if(window.localStorage.getItem("moodle-console-urlUpgrade"))
      window.localStorage.setItem("moodle-console-urlUpgrade", '');

    const [requestData , setRequestData] = useState<DataType>();
    const [isCreatingComponent, setIsCreatingComponent] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);    
    const [isUpgrading, setIsUpgrading] = useState<boolean>(false);
    const [showOuput, setShowOuput] = useState<boolean>(false);
    const [contentOuput, setContentOuput] = useState<string>("");
    const [outputFileId, setOutputFileId] = useState<string>("");
    const [isUpgradeFailed, setIsUpgradeFailed] = useState<boolean>(false);

    const [isRollingBack, setIsRollingBack] = useState<boolean>(false);
    const [showRollbackOutput, setShowRollbackOutput] = useState<boolean>(false);
    const [contentRollbackOutput, setContentRollbackOutput] = useState<string>("");
    const [outputRollbackFileId, setOutputRollbackFileId] = useState<string>("");

  const handleUpgrade = () => {
    if (window.confirm(`Are you sure you want to upgrade the LMS ${loaderData.action === 'upgrade-lms'
      ? requestData?.volumeName
      : requestData?.instanceName}?`
    ))
      upgradeFunction();
  }
  const upgradeFunction = async () => {
    try {
      // Begin upgrade process
      setIsLoading(true); //show loading element
      const response = await api.post(`/instances/${requestData?.instanceId}/upgrade-lms`,
        {
          token: loaderData.token,
          parameters: requestData,
          deployment: loaderData.action === 'upgrade-lms' ? 'shared' : 'dedicated',
        }
      );

      const data = response.data;
      console.log("response del upgrade: ", data);

      if (data.success) {
        setIsLoading(false); //hide loading element
        if (data.success && data.outputFileId) {
          setOutputFileId(data.outputFileId);
          setIsUpgrading(true);
          setShowOuput(true);
        } else
          return {
            error: true,
            message:
              "An error has occurred. Cannot display the output of the upgrade process.",
          };
      }
    } catch (error) {
      setIsLoading(false); //hide loading element
      if (error instanceof AxiosError && error.response) {
        const response = error.response;
        const data = response.data;

        if (response.status === 401) 
          return redirect("/");
        else 
          if (data.message)
            alert(data.message);
          else
            alert("An error has occurred. Failed to upgrade the LMS.");
      }
    }
  };

  const handleRollback = () => {
    if (window.confirm("Are you sure you want to rollback the upgrade process?")) {
      //setShowRollbackOutput(true); // Muestra el panel de salida del rollback
      rollbackFunction(); // Llama a la función del rollback, que debería actualizar `contentOutput` en cada paso
    }
  };
  const rollbackFunction = async () => {
    try {
      // Begin upgrade process
      setIsLoading(true); //show loading element
      const response = await api.post(`/instances/${requestData?.instanceId}/upgrade-rollback-lms`,
        {
          token: loaderData.token,
          parameters: requestData,
          deployment: loaderData.action === 'upgrade-lms' ? 'shared' : 'dedicated',
        }
      );

      const data = response.data;
      console.log("response del rollback: ", data);

      if (data.success) {
        setIsLoading(false); //hide loading element
        if (data.success && data.outputFileId) {
          setOutputRollbackFileId(data.outputFileId);
          setIsRollingBack(true);
          setShowRollbackOutput(true);
        } else
          return {
            error: true,
            message:
              "An error has occurred. Cannot display the output of the upgrade rollback process.",
          };
      }
    } catch (error) {
      setIsLoading(false); //hide loading element
      if (error instanceof AxiosError && error.response) {
        const response = error.response;
        const data = response.data;

        if (response.status === 401) 
          return redirect("/");
        else 
          if (data.message)
            alert(data.message);
          else
            alert("An error has occurred. Failed to rollback the upgrade process.");
      }
    }
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure you want to cancel the upgrade request?"))
      cancelFunction();
  };
  const cancelFunction = async () => {
    try {
      setIsLoading(true); //show loading element
      const response = await api.delete(`/instances/${requestData?.instanceId}/upgrade-request?token=${loaderData.token}&status=canceled`);
      const data = response.data;
      console.log("response del cancelFunction: ", data);
      
      setIsLoading(false); //hide loading element
      if (data.success) {
        alert("The upgrade request has been successfully canceled.");
        //hacer redirect a /instances
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const response = error.response;
        const data = response.data;

        if (response.status === 401) 
            return redirect("/");
        else 
          if (data.message)
            alert(data.message);
          else
            alert("An error has occurred. Failed to cancel the upgrade request.");
      }
    }
  };
    
    useEffect(() => {
        if (isCreatingComponent) {
            setIsLoading(true); //show loading element

            //Getting request details
            api
                .get(`/instances/upgrade-details?region=${region}&token=${loaderData.token}&action=${loaderData.action}`)
                .then(({ data }) => {
                    console.log("data from upgrade-details: ", data);
                    if (data.success && data.request) {
                        setRequestData(data.request);
                        setIsLoading(false);
                    }
                })
                .catch((err: AxiosError) => {
                    setIsLoading(false);
                    if (err.response) {
                        const result = err.response.data as any;
                        if (result && result.message) {
                            alert(result.message);
                        }
                    } else {
                        alert("An error has occurred. Failed to load upgrade request details.");
                    }
                });

            setIsCreatingComponent(false);
        }
    }, [isCreatingComponent, region, loaderData.token, loaderData.action]);
    
/*     useEffect(() => {
        console.log("useEffect from Upgrade.tsx");

        if (isUpgrading) {
            let timeout: number;

            const loopRequest = () => {
                timeout = window.setTimeout(() => {
                    const url = `/instances/${requestData?.instanceId}/upgrade-process-info?outputFileId=${outputFileId}`;
                    api
                        .get(url)
                        .then(async (response) => {
                            const output = response.data;
                            if (response.status === 200) {
                                //finished the process
                                setIsUpgrading(false);
                                setContentOuput(output);
                            } else if (response.status === 206) {
                                //continue the process
                                setContentOuput(output);
                                loopRequest();
                            }
                        })
                        .catch((error) => {
                            // do something with the error
                            console.log("Error from process: ");
                            console.log(error.message);
                            setIsUpgrading(false);
                            setErrorMessage(error.message);
                        });
                }, 4000);
            };

            loopRequest();

            return () => {
                if (typeof timeout === "number") clearTimeout(timeout);
            };
        }
    }, [isUpgrading, outputFileId, requestData?.instanceId]); */
  
  useEffect(() => {
    console.log("useEffect from Upgrade.tsx");

    if (isUpgrading) {
      setIsLoading(true); //show loading element
      let timeout: number;

      const loopRequest = () => {
        timeout = window.setTimeout(() => {
          const url = `/instances/${requestData?.instanceId}/upgrade-process-info?outputFileId=${outputFileId}`;
          api
            .get(url)
            .then((response) => {
              const { content, processStatus } = response.data;

              setContentOuput(content);

              if (processStatus === "success") {
                // Proceso completado exitosamente
                setIsUpgrading(false);
                setIsLoading(false); //hide loading element
              } else if (processStatus === "error") {
                // Proceso completado con errores
                setIsUpgrading(false);
                setIsUpgradeFailed(true);
                setIsLoading(false); //hide loading element
                alert("The process completed with errors. You may rollback the process by clicking the 'Rollback Process' button.");
              } else if (processStatus === "inProgress") {
                // Proceso aún en progreso
                loopRequest();
              }
            })
            .catch((error) => {
              console.log("Error from process: ", error.message);
              setIsUpgrading(false);
              setIsLoading(false); //hide loading element
              alert(error.message);
            });
        }, 4000);
      };

      loopRequest();

      return () => {
        if (typeof timeout === "number") clearTimeout(timeout);
      };
    }
  }, [isUpgrading, outputFileId, requestData?.instanceId]);

  useEffect(() => {
    console.log("useEffect of Rollback");

    if (isRollingBack) {
      setIsLoading(true); //show loading element
      let timeout: number;

      const loopRollback = () => {
        timeout = window.setTimeout(() => {
          // Se usa el mismo endpoint para mostrar la salida del upgrade y del rollback pero se le pasa un identificador de archivo de salida diferente dado que cada proceso genera su propio archivo de salida
          const url = `/instances/${requestData?.instanceId}/upgrade-process-info?outputFileId=${outputRollbackFileId}`;
          api
            .get(url)
            .then((response) => {
              const { content, processStatus } = response.data;

              setContentRollbackOutput(content);

              if (processStatus === "success") {
                // Proceso completado exitosamente
                setIsRollingBack(false);
                setIsLoading(false); //hide loading element
              } else if (processStatus === "error") {
                // Proceso completado con errores
                setIsRollingBack(false);
                setIsLoading(false); //hide loading element
                alert("The rollback process was completed with errors.");
              } else if (processStatus === "inProgress") {
                // Proceso aún en progreso
                loopRollback();
              }
            })
            .catch((error) => {
              console.log("Error from process: ", error.message);
              setIsRollingBack(false);
              setIsLoading(false); //hide loading element
              alert(error.message);
            });
        }, 3000);
      };

      loopRollback();

      return () => {
        if (typeof timeout === "number") clearTimeout(timeout);
      };
    }
  }, [isRollingBack, outputRollbackFileId, requestData?.instanceId]);
    
    return (
      <div className="container">
        <div
          className={
            isUpgrading || isLoading || navigation.state === "loading"
              ? "loading"
              : ""
          }
        ></div>
        <div className="row">
          <div className="col-12 pt-5">
            <div className="row">
              <h2 id="delete-resources-title">Upgrade LMS</h2>

              {/* Columna 1: Detalles del LMS */}
              <div className="col-4">
                <div className="delete-form-container">
                  <div id="delete-instance-form" className="mt-2 mb-3">
                    <h3>Details</h3>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="instanceId">
                        Instance Id
                      </label>
                      <input
                        className="form-control"
                        id="instanceId"
                        type="text"
                        defaultValue={requestData?.instanceId ?? ""}
                        readOnly={true}
                        disabled={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="instanceName">
                        Instance Name
                      </label>
                      <input
                        className="form-control"
                        id="instanceName"
                        type="text"
                        defaultValue={requestData?.instanceName ?? ""}
                        readOnly={true}
                        disabled={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="instanceIp">
                        Public IP Address
                      </label>
                      <input
                        className="form-control"
                        id="instanceIp"
                        type="text"
                        defaultValue={requestData?.instanceIp ?? ""}
                        readOnly={true}
                        disabled={true}
                      />
                    </div>
                    {loaderData.action === 'upgrade-lms' && requestData?.volumeId && (
                        <div className="mb-3">
                          <label className="form-label" htmlFor="volumeId">
                            Volume Id
                          </label>
                          <input
                            className="form-control"
                            id="volumeId"
                            type="text"
                            defaultValue={requestData?.volumeId ?? ""}
                            readOnly={true}
                            disabled={true}
                          />
                        </div>
                    )}
                    {loaderData.action === 'upgrade-lms' && requestData?.volumeName && (
                        <div className="mb-3">
                          <label className="form-label" htmlFor="volumeId">
                            Volume Name
                          </label>
                          <input
                            className="form-control"
                            id="volumeId"
                            type="text"
                            defaultValue={requestData?.volumeName ?? ""}
                            readOnly={true}
                            disabled={true}
                          />
                        </div>
                    )}
                    <div className="mb-3">
                      <label className="form-label" htmlFor="instanceDomain">
                        Domain
                      </label>
                      <input
                        className="form-control"
                        id="instanceDomain"
                        type="text"
                        defaultValue={requestData?.domain}
                        readOnly={true}
                        disabled={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="lms">
                        LMS
                      </label>
                      <input
                        className="form-control"
                        id="lms"
                        type="text"
                        defaultValue={requestData?.lms}
                        readOnly={true}
                        disabled={true}
                      />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="appDirectory">
                            Site Root Directory
                        </label>
                        <input
                            className="form-control"
                            id="appDirectory"
                            type="text"
                            defaultValue={requestData?.appDirectory ?? ""}
                            readOnly={true}
                            disabled={true}
                        />
                    </div>
                    <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="dbInstanceIdentifier"
                        >
                          RDS
                        </label>
                        <input
                          className="form-control"
                          id="dbInstanceIdentifier"
                          type="text"
                          defaultValue={requestData?.dbInstanceId.split('.')[0] ?? ""}
                          readOnly={true}
                          disabled={true}
                        />
                    </div>
                    <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="dbName"
                        >
                          Database Name
                        </label>
                        <input
                          className="form-control"
                          id="dbName"
                          type="text"
                          defaultValue={requestData?.dbName ?? ""}
                          readOnly={true}
                          disabled={true}
                        />
                    </div>
                    <div className="text-end actions-buttons" /* className="d-flex actions-buttons" */>
                      {!showOuput && canUser([ROLE.SUPERADMIN]) && (
                        <>
                          <button
                            type="button"
                            className="btn btn-custom me-3"
                            onClick={handleUpgrade}
                          >
                            Upgrade
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary me-3 cancel-button"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </>
                      )}
                      {isUpgradeFailed && !showRollbackOutput && canUser([ROLE.SUPERADMIN]) && (
                        <button
                          type="button"
                          className="btn btn-custom me-3"
                          onClick={handleRollback}
                        >
                          Rollback Process
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => navigate("/home/instances")}
                      >
                        Instances List
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Columna 2: Salida de Upgrade */}
              {showOuput && (
                <div className="col-4">
                  <div className="delete-details-container mt-2">
                    <h3>Upgrade Output</h3>
                  </div>
                  <div id="output-board" className="form-control output-scroll">
                    {contentOuput}
                  </div>
                </div>
              )}

              {/* Columna 3: Salida de Rollback */}
              {showRollbackOutput && (
                <div className="col-4">
                  <div className="delete-details-container mt-2">
                    <h3>Rollback Output</h3>
                  </div>
                  <div id="rollback-output-board" className="form-control output-scroll">
                    {contentRollbackOutput}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Upgrade; 