import { FC, useRef } from "react";

interface DragDropFileProps {
    instanceId: string;
    instanceIp: string;
    region: any;
    path: string;
    onUpload: (
        instanceId: string, 
        instanceIp: string, 
        region: string, 
        path: string, 
        file: any
        ) => Promise<void>;
    disabled: boolean;
    isPlugin: boolean;
}

const DragDropFile: FC<DragDropFileProps> = ({ onUpload, instanceId, instanceIp, region, path, disabled, isPlugin }) => {

    const wrapperRef = useRef<HTMLDivElement>(null);

    const onDragEnter = () => {
        if(wrapperRef.current)
            wrapperRef.current.classList.add('dragover');
    }

    const onDragLeave = () => {
        if(wrapperRef.current)
            wrapperRef.current.classList.remove('dragover');
    }

    const onDrop = () => {
        if(wrapperRef.current)
            wrapperRef.current.classList.remove('dragover');
    }

    const UploadFile = (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        console.log(file)
 
        onUpload(instanceId, instanceIp, region, path, file)
     }

    return (
        <>
            <div 
                ref={wrapperRef} 
                className={ disabled ? "drop-file-input not-upload-allowed" : "drop-file-input"  } 
                onDragEnter={onDragEnter} 
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                    <div className={ disabled ? "drop-file-input-label not-enabled" : "drop-file-input-label" } > 
                        <img src="/images/cloud-upload.png" alt="" />
                        <p>Drag and Drop your file here</p>
                    </div>
                    <input disabled={disabled}
                        type="file" value=""
                        accept={ isPlugin ? "application/zip" : "image/*, video/*, application/pdf, application/zip" }
                        onChange={(e) => {
                            UploadFile(e)                        
                        }} 
                    />
            </div>
        </>
    );

}

export default DragDropFile;